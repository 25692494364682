<template>
  <a-layout class='index animated fadeIn'>
    <div class="s_bf">
      <a-form ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-item ref="level" label="资质名称" name="level">
          <div class="s_flex_ali">
            <p class="s_c333 mr15">企业营业执照</p>
            <div class="s_flex_ali">
              <WarningOutlined v-if='status !== 1' class="s_cd41" />
              <CheckOutlined v-else class="s_c376" />
              <p>{{status_text}}</p>
            </div>
          </div>
        </a-form-item>
        <a-form-item ref="business_name" label="企业名称" name="business_name">
          <a-input v-model:value="form.business_name" placeholder='请输入企业名称' readonly />
        </a-form-item>
        <a-form-item ref="license_no" label="营业执照号码" name="license_no" >
          <a-input-group compact >
            <a-select style="width: 15%" v-model:value="form.license_type" disabled>
              <a-select-option value="1">中国</a-select-option>
              <a-select-option value="2">国外</a-select-option>
            </a-select>
            <a-input style="width: 85%" v-model:value="form.license_no" placeholder='营业执照号码' readonly />
          </a-input-group>
        </a-form-item>
        <a-form-item ref="bar_code" label="组织机构代码" name="bar_code">
          <a-input v-model:value="form.bar_code" placeholder='请输入组织机构代码' readonly />
        </a-form-item>
        <a-form-item ref="license_images" label="营业执照" name="license_images">
          <div>
            <a-image-preview-group>
              <a-image v-for='item in form.license_images' :key='item' :width='180' :height='180' src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"/>
            </a-image-preview-group>
          </div>
        </a-form-item>
        <a-form-item ref="business_addr" label="企业地址" name="business_addr">
          <a-input v-model:value="form.business_addr" placeholder='请输入企业地址' readonly />
        </a-form-item>
      </a-form>
    </div>
  </a-layout>
</template>
<script>
  import { WarningOutlined, CheckOutlined } from "@ant-design/icons-vue";
  import { ref, onMounted, reactive, toRefs, inject,watchEffect } from 'vue';
  import { message } from 'ant-design-vue';
  import store from "@/store";
  // 初始化默认筛选项数值
  let defForm = {
    license_type: '1',
    business_name:'',
    business_addr: '',
    license_no: '',
    license_images: '',
    bar_code:''
  }
  export default {
    name:'licence',
    components: {WarningOutlined, CheckOutlined },
    props: {
      formData: {
        type: Object
      },
    },
    setup(props,{emit}) {
      //pass
      const form = ref({ ...defForm });
      const state = reactive({
        info: store.state.user.info,
        status:0,
        status_text:'未认证',
        labelCol: { span: 4 },
        wrapperCol: { span: 20 },
      });
      const ruleForm = ref()
      const _lodash = inject("_lodash");
      const onSubmit = () => {
        ruleForm.value.validate().then(() => {
          emit('onsubmit',form.value)
        }).catch(error => {
            console.log('error', error);
        });
      }
      onMounted(() => {
      });
      
      watchEffect(() => {
        var formData = props.formData || {};
        console.log(formData)
        if (!_lodash.isEmpty(formData.content)) {
          form.value = formData.content;
          state.status = formData.status
          state.status_text = formData.status_text
        } else {
          form.value = defForm;
          state.status = formData.status
          state.status_text = formData.status_text
        }
      });
      return {
        form,
        ...toRefs(state),
        ruleForm,
        onSubmit
      }
    }
  }
</script>
<style>
</style>